import {
    PartnersReportActionsProps,
    PartnersReportContext,
    PartnersReportContextProps
} from "pages/reports/partners/PartnersReportContext.model";
import {useContext, useEffect, useState} from "react";
import {PartnerStatistics, PartnerStatisticsRequestFilter} from "api/statistics/PartnerStatisticsResource";
import {AlertProps} from "components/feedback/Alert";
import {getPartnersStatistics} from "api/statistics/StatisticsApi";
import {useTranslation} from "react-i18next";
import {UmzugEasyTableProps} from "components/table/UmzugEasyTable";
import useUserProfileInfo from "hooks/useUserProfileInfo";
import {getPartnersStatisticsTableSchema} from "pages/reports/partners/PartnersReportTableSchema";

export const getInitialPartnersStatisticsFilter = (name?: string): PartnerStatisticsRequestFilter => {
    return {
        hasActiveContract: true,
        ownerDetails: name,
    }
}

export const useInitPartnersReportsContext = (): PartnersReportContextProps => {
    const [userProfileLoaded, setUserProfileLoaded] = useState(false)
    const [userProfile] = useUserProfileInfo();
    const [loading, setLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertProps | undefined>(undefined)
    const [showPreviousMonths, setShowPreviousMonths] = useState<boolean>(false);

    const [partnersStatistics, setPartnersStatistics] = useState<PartnerStatistics[]>([]);
    const [partnersStatisticsDate, setPartnersStatisticsDate] = useState<Date | undefined>(undefined);
    const [partnersStatisticsFilter, setPartnersStatisticsFilter] = useState<PartnerStatisticsRequestFilter>(getInitialPartnersStatisticsFilter(userProfile?.name));
    const [sortBy, setSortBy] = useState<string>("revenue.currentYear:desc");
    const [page, setPage] = useState(-1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [tableProps, setTableProps] = useState<UmzugEasyTableProps<PartnerStatistics> | undefined>(undefined);

    const {t} = useTranslation('partnersReport')

    const showAlert = (translationKey: string) => {
        setAlert({
            color: 'error',
            open: true,
            text: t(`alerts.${translationKey}`),
            handleClose: () => setAlert(undefined)
        })
    }

    const loadPartnersStatistics = (pageForLoad: number) => {
        if (pageForLoad === 0) {
            setLoading(true)
        }
        getPartnersStatistics(pageForLoad, 20, sortBy, partnersStatisticsFilter)
            .then(partnerStatistics => {
                if (pageForLoad === 0) {
                    setPartnersStatistics(partnerStatistics.data._embedded?.partners || [])
                } else {
                    setPartnersStatistics(prevState => [...prevState, ...(partnerStatistics.data._embedded?.partners || [])])
                }
                setPartnersStatisticsDate(partnerStatistics.serverTime)
                setTotalElements(partnerStatistics.data.page.totalElements)
                setTotalPages(partnerStatistics.data.page.totalPages)
                setPage(pageForLoad)
            })
            .catch(() => showAlert('getPartnersStatisticsError'))
            .finally(() => setLoading(false))
    }



    const onSortByChange = (key: string, direction: 'asc' | 'desc' | undefined) =>
        setSortBy(prevState => {
                const newSortByArray = prevState
                    .split(',')
                    .filter((value) => !value.startsWith(key))
                if(direction !== undefined) {
                    newSortByArray.unshift(`${key}:${direction}`)
                }
                return newSortByArray.join(',')
            }
        )

    const actions: PartnersReportActionsProps = {
        onOpenPartnerDetails: () => {
        },
        onLoadNextPage: () => {
            if (page < totalPages) {
                loadPartnersStatistics(page + 1);
            }
        },
        onFilterChange: (filter: PartnerStatisticsRequestFilter) => setPartnersStatisticsFilter(filter),
        onSortByChange: (sortField: string, direction: string) => setSortBy((prevState) => `${sortField}:${direction},${prevState}`),
        onShowPreviousMonths: (active: boolean) => setShowPreviousMonths(active)
    }

    useEffect(() => {
        if (userProfile?.name !== undefined) {
            setPartnersStatisticsFilter(getInitialPartnersStatisticsFilter(userProfile?.name))
            setUserProfileLoaded(true)
        }
    }, [userProfile])

    useEffect(() => {
        if (userProfileLoaded) {
            loadPartnersStatistics(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnersStatisticsFilter, sortBy])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setTableProps(getPartnersStatisticsTableSchema(showPreviousMonths, onSortByChange, t, partnersStatistics, partnersStatisticsDate)), [partnersStatistics, showPreviousMonths])

    return {
        partnersStatistics,
        partnersStatisticsFilter,
        loading,
        showPreviousMonths,
        page,
        totalPages,
        totalElements,
        tableProps,
        alert,
        actions
    }
}

export function usePartnersReportsContext() {
    return useContext(PartnersReportContext)
}