import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Section from "components/form/Section";
import {Grid} from "@mui/material";
import Label from "components/form/Label";
import ToggleSwitch from "components/form/ToggleSwitch";
import {usePartnerContext} from "pages/partner/shared/PartnerContext";
import Button from "components/form/Button";
import {PartnerSettingsOverduePaymentsRequest} from "api/partnerSettings/PartnerSettingsResource";
import {formatToLocaleTextWithDate} from "utils/DateUtil";

const SPACING = 3;
const ROW_ALIGN = 'center';
const COLUMNS_NUM = 16;
const DunningState: React.FC = () => {
    const {t} = useTranslation('dashboard')
    const {partner, partnerSettings, toggleHasOverduePayments, resetDeactivationDate} = usePartnerContext()
    const [hasOverduePayments, setHasOverduePayments] = useState<boolean>(!!partnerSettings?.hasOverduePayments)
    const [deactivationCounter, setDeactivationCounter] = useState<number>(0)

    const calculateDaysRemaining = (deactivationDateString: string) => {
        const now = new Date()
        const deactivationDate = new Date(deactivationDateString)
        now.setHours(0, 0, 0, 0)
        deactivationDate.setHours(0, 0, 0, 0)
        const differenceInTime = deactivationDate.getTime() - now.getTime()
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))
        setDeactivationCounter(differenceInDays)
    }

    useEffect(() => {
        const deactivationDate = partnerSettings?.deactivationDate
        if (!!deactivationDate) {
            calculateDaysRemaining(deactivationDate)
        }
    }, [partnerSettings?.deactivationDate])

    const handleChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        if (partner?.cwid != null && partnerSettings != null) {
            const newValue = value.target.checked
            const request: PartnerSettingsOverduePaymentsRequest = {hasOverduePayments: newValue}
            toggleHasOverduePayments(partner?.cwid, request)
            setHasOverduePayments(newValue)
        }
    }

    const resetDeactivationCounter = () => {
        if (partner?.cwid != null && partnerSettings != null) {
            resetDeactivationDate(partner?.cwid)
        }
    }

    const isDeactivated = !!partnerSettings?.hasOverduePayments || (!!partnerSettings?.deactivationDate && deactivationCounter <= 0)
    const hasNoDeactivationDate = !partnerSettings?.deactivationDate

    const activeDeactivationCounterLabel =
        <Grid item xs={5}>
            <Label sx={{textAlign: 'left'}}
                   label={t('dunningState.status.activeCounter', {'days': deactivationCounter})}/>
        </Grid>

    const inactiveDeactivationCounterLabel =
        <Grid item xs={5}>
            <Label sx={{textAlign: 'left'}} label={t('dunningState.status.inactiveCounter')}/>
        </Grid>

    const partnerDeactivatedLabel =
        <Grid item xs={6}>
            <Label sx={{textAlign: 'left'}}
                   label={t('dunningState.status.partnerDeactivated', {'date': formatToLocaleTextWithDate(partnerSettings?.deactivationDate)})}/>
        </Grid>

    return (
        <Section dataTestId={'dunningState'} title={t('dunningState.title')}>
            <Grid container spacing={SPACING} alignItems={ROW_ALIGN} columns={COLUMNS_NUM}>
                <Grid item xs={2}><Label sx={{textAlign: 'right', lineHeight: '20px', paddingTop: "10px"}}
                                         label={t('dunningState.toggle.label')}/></Grid>
                <Grid item xs={1}>
                    <ToggleSwitch checked={hasOverduePayments}
                                  onClick={(event) => event.stopPropagation()}
                                  onChange={(value) => handleChange(value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={SPACING} alignItems={ROW_ALIGN} columns={COLUMNS_NUM}>
                <Grid item xs={2}><Label sx={{textAlign: 'right'}} label={t('dunningState.status.label')}/></Grid>
                {isDeactivated ? partnerDeactivatedLabel : !!partnerSettings?.deactivationDate ? activeDeactivationCounterLabel : inactiveDeactivationCounterLabel}
                <Grid item xs={1}> <Button
                    label={t('dunningState.button')} type={'button'}
                    onClick={resetDeactivationCounter}
                    disabled={hasNoDeactivationDate}
                />
                </Grid>
            </Grid>
        </Section>
    )
}

export default DunningState