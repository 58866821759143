import {FC, ReactElement} from "react";
import Box from '@mui/material/Box';
import RelocationRequestDetailHeader from "./RelocationRequestDetailHeader";
import RelocationRequestDetailTabs from "./RelocationRequestDetailTabs";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import { useRelocationRequestContext} from "../../RelocationRequests";

const relocationRequestDetailStyles = makeStyles((theme: Theme) => ({
    relocationRequestDetail: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 70px 0 70px',
        height: 'calc(100vh - 280px)',
        border: '1px solid white',
        width: '100%'
    }
}))

const RelocationRequestDetail: FC = (): ReactElement => {
    const { relocationRequestDetail, relocationRequestDetailLoading, onAnonymizeRelocationRequest, anonymizationConfirmationModal, isAnonymizingRelocationRequest, leads } = useRelocationRequestContext()
    const classes = relocationRequestDetailStyles()
    const consumerName = `${relocationRequestDetail?.consumer.firstName} ${relocationRequestDetail?.consumer.lastName}`

    return (
        <Box className={classes.relocationRequestDetail}>
            <RelocationRequestDetailHeader
                requestId={relocationRequestDetail?.anfrageId || ''}
                name={consumerName} premium={!!relocationRequestDetail?.premium}
                loading={relocationRequestDetailLoading}
            />
            { relocationRequestDetail &&
                <RelocationRequestDetailTabs
                    relocationRequestDetail={relocationRequestDetail}
                    loading={relocationRequestDetailLoading}
                    onAnonymizeRelocationRequest={onAnonymizeRelocationRequest}
                    anonymizationConfirmationModal={anonymizationConfirmationModal}
                    isAnonymizingRelocationRequest={isAnonymizingRelocationRequest}
                    leads={leads}
                /> }
        </Box>
    )
}
export default RelocationRequestDetail
