import {isAfter, isBefore, parseISO, startOfDay} from "date-fns";

const ONE_MINUTE_IN_MILLISECONDS = 60000

// Converts given date to DD.MM.YYYY HH:MM:SS format text
export function formatToLocaleTextWithDateTime(date: Date | string |undefined, locales = 'de-DE'): string {
    if (!date) {
        return '';
    }

    if (date instanceof Date) {
        return `${date.toLocaleDateString(locales)} ${date.toLocaleTimeString(locales)}`;
    }

    const dateObj = new Date(date);

    return `${dateObj.toLocaleDateString(locales)} ${dateObj.toLocaleTimeString(locales)}`;
}

// Converts given date to YYYY-MM-DD format text
export function formatToIsoTextWithDate(date: Date | string | undefined): string {
    if (!date) {
        return '';
    }

    const dateObj = new Date(date);

    return dateObj.toISOString().split('T')[0];
}

// Converts given local date to YYYY-MM-DD format text
export function formatToIsoTextWithLocalDate(date: Date | string | undefined): string {
    const addLeadingZero = (toConvert: number): string => toConvert < 10 ? '0' + toConvert : '' + toConvert;

    if (!date) {
        return '';
    }

    const dateObj = new Date(date);

    const dateArray = dateObj.toLocaleDateString('de-DE').split(".");
    const month = addLeadingZero(Number(dateArray[1]));
    const day = addLeadingZero(Number(dateArray[0]));
    return `${dateArray[2]}-${month}-${day}`;
}

export function formatToLocaleTextWithDate(date: Date | string | undefined, locales = 'de-DE'): string {
    if (!date) {
        return '';
    }

    const dateObj = new Date(date);

    return dateObj.toLocaleDateString(locales)
}

export function compareDates(date1: Date | string, date2: Date | string, order: 'asc' | 'desc' = 'asc'): number {
    const date1Obj = new Date(date1);
    const date2Obj = new Date(date2);

    return (date1Obj.getTime() - date2Obj.getTime()) * (order === 'asc' ? 1 : -1);
}

export function getNextDay(date: Date | null | undefined): Date | null | undefined {
  if (date === null || date === undefined) return null;

  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 1);
  return nextDay;
}

export function getDayBefore(date: Date | null | undefined): Date | null | undefined {
  if (date === null || date === undefined) return null;

  const dayBefore = new Date(date);
  dayBefore.setDate(date.getDate() - 1);
  return dayBefore;
}

export const reduceOffset = (date: Date | null | undefined) => date == null ? null : new Date(date.getTime() - (date.getTimezoneOffset() * ONE_MINUTE_IN_MILLISECONDS))
export const addOffset = (date: Date | null | undefined) => date == null ? null : new Date(date.getTime() + (date.getTimezoneOffset() * ONE_MINUTE_IN_MILLISECONDS))


export const isBeforeOrEqual = (date: string, comparisonDate: Date) => {
    return isBefore(startOfDayDate(date), comparisonDate) || startOfDayDate(date).getTime() === comparisonDate.getTime();
};

export const isAfterOrEqual = (date: string, comparisonDate: Date) => {
    return isAfter(startOfDayDate(date), comparisonDate) || startOfDayDate(date).getTime() === comparisonDate.getTime();
};

export const startOfDayDate = (dateString: string) => startOfDay(parseISO(dateString));

export const getCurrentDayBeginningOfTheDay = (): Date => {
    const now = new Date();
    return  new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
}
