import {AlgotestRelocationRequest} from "../../../../api/algotest/AlgotestRelocationRequest";
import {AlgotestFilterValues, INITIAL_ALGOTEST_FILTER_VALUES} from "./AlgotestFilterModel";
import {format} from "date-fns";

export const algotestFormToModel = (relocationRequest: AlgotestRelocationRequest): AlgotestFilterValues => {
    const getDate = (relocationRequest: AlgotestRelocationRequest): Date => {
        const date = relocationRequest.date?.value
        if (date == null) {
            return INITIAL_ALGOTEST_FILTER_VALUES.date;
        }
        return new Date(date)
    }
    return {
        date: getDate(relocationRequest),
        flexibleDate: relocationRequest.date?.type || INITIAL_ALGOTEST_FILTER_VALUES.flexibleDate,
        relocationType: relocationRequest.relocationType || INITIAL_ALGOTEST_FILTER_VALUES.relocationType,
        payment: relocationRequest.payment || INITIAL_ALGOTEST_FILTER_VALUES.payment,
        squareMeters: relocationRequest.livingSpaceAreaSqm || INITIAL_ALGOTEST_FILTER_VALUES.squareMeters,
        distance: relocationRequest.distance || INITIAL_ALGOTEST_FILTER_VALUES.distance,
        affiliate: relocationRequest.affiliatePartner || INITIAL_ALGOTEST_FILTER_VALUES.affiliate,
        affiliateParameter: relocationRequest.affiliatePartnerParameter || INITIAL_ALGOTEST_FILTER_VALUES.affiliateParameter,
        fromCountry: relocationRequest.from?.country || INITIAL_ALGOTEST_FILTER_VALUES.fromCountry,
        fromPostalCode: relocationRequest.from?.postalCode || INITIAL_ALGOTEST_FILTER_VALUES.fromPostalCode,
        fromCity: relocationRequest.from?.city || INITIAL_ALGOTEST_FILTER_VALUES.fromCity,
        toCountry: relocationRequest.to?.country || INITIAL_ALGOTEST_FILTER_VALUES.toCountry,
        toPostalCode: relocationRequest.to?.postalCode || INITIAL_ALGOTEST_FILTER_VALUES.toPostalCode,
        toCity: relocationRequest.to?.city || INITIAL_ALGOTEST_FILTER_VALUES.toCity,
        premium: relocationRequest.premium || INITIAL_ALGOTEST_FILTER_VALUES.premium,
        disabledSteps: relocationRequest.disabledSteps || INITIAL_ALGOTEST_FILTER_VALUES.disabledSteps
    }
}

export const algotestModelToForm = (form: AlgotestFilterValues): AlgotestRelocationRequest => {
    return {
        date: {
            value: format(form.date, 'yyyy-MM-dd'),
            type: form.flexibleDate
        },
        from: {
            country: form.fromCountry,
            postalCode: form.fromPostalCode,
            city: form.fromCity,
        },
        to: {
            country: form.toCountry,
            postalCode: form.toPostalCode,
            city: form.toCity,
        },
        relocationType: form.relocationType,
        livingSpaceAreaSqm: form.squareMeters,
        distance: form.distance,
        payment: form.payment,
        affiliatePartner: form.affiliate,
        affiliatePartnerParameter: form.affiliateParameter,
        premium: form.premium,
        disabledSteps: form.disabledSteps || [],
    }
}
