import {FC, ReactElement} from "react";
import {makeStyles} from "@mui/styles";
import {Box, Skeleton, Theme} from "@mui/material";
import {useTranslation} from "react-i18next";

export type RelocationRequestDetailHeaderProps = {
    requestId: string;
    name: string;
    premium: boolean;
    loading: boolean;
}

const relocationRequestDetailHeaderStyles = makeStyles((theme: Theme) => ({
    partnerId: {
        fontSize: "24px",
        fontWeight: "600",
        wordBreak: "break-all"
    },
    partnerName: {
        fontSize: "24px",
        whiteSpace: "nowrap",
        fontWeight: "400"
    }
}))

const RelocationRequestDetailHeader: FC<RelocationRequestDetailHeaderProps> = ({ requestId, name, premium, loading }: RelocationRequestDetailHeaderProps): ReactElement => {
    const classes = relocationRequestDetailHeaderStyles()
    const commonSkeletonStyles = {
        fontSize: "24px",
        display: "inline-block"
    }
    const {t} = useTranslation('relocationRequests')

    return (
        loading ?
            <Box>
                <Skeleton sx={{ ...commonSkeletonStyles ,width: '850px', mr: '20px' }} variant="text" />
                <Skeleton sx={{ ...commonSkeletonStyles, width: '150px' }} variant="text" />
            </Box>
            :
            <Box>
                <Box component='span' className={classes.partnerId}>{`${requestId} ${premium ? `- ${t('requestDetails.header.type.premium')}` : ''} `}</Box>
                <Box component='span' className={classes.partnerName}>{name}</Box>
            </Box>
    )
}
export default RelocationRequestDetailHeader
