import {TableHeader} from "../model/UmzugEasyTableModel";
import {MutableRefObject, useEffect, useState} from "react";

const DEFAULT_ORDER = 9999; // if we don't define the order column would be placed at the end of the table

export function useSortedHeaders<T>(headers: TableHeader<T>[]) {
    const [sortedHeaders, setSortedHeaders] = useState<TableHeader<T>[]>([]);
    useEffect(() => {
        const getValue = (header: TableHeader<T>): number => header.order || DEFAULT_ORDER;
        setSortedHeaders(headers
            .filter(header => !header.hide)
            .sort((a, b) => {
                return getValue(a) > getValue(b) ? 1 : -1
            }));
    }, [headers])
    return sortedHeaders;
}

export function useOnScreen(ref: MutableRefObject<any>) {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting)
        )
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect()
        }
    }, [ref])

    return {isIntersecting, setIntersecting}
}