import React, {FC, ReactElement, useState} from "react";
import {Box, Typography} from "@mui/material";
import DatePicker from "components/form/DatePicker";
import {set, subDays, subMonths} from "date-fns";
import {useTranslation} from "react-i18next";
import RequestsAndLeadsReport from "pages/reports/overview/RequestsAndLeadsReport";
import { formatToLocaleTextWithDate } from "utils/DateUtil";
import RequestTypesReport from "pages/reports/overview/RequestsTypesReport";
import RequestSourcesReport from "pages/reports/overview/RequestsSourcesReport";

const today = new Date()
const yesterday = subDays(today, 1)
const oneMonthAgo = subMonths(today, 1)
const twoMongthsAgo = subMonths(today, 2)
const oneMonthAndOneDayAgo = subDays(oneMonthAgo, 1)
const countryCodes = ['DE', 'CH', 'AT']

const getStartDateTime = (startDate: Date) => {
    return set(startDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
}

const getEndDateTime = (endDate: Date) => {
    return set(endDate, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
}

const OverviewReport: FC = (): ReactElement => {
    //TODO: jbrylak implement range widget
    const reportsTranslation = useTranslation('reports').t
    const [startDate, setStartDate] = useState<Date>(getStartDateTime(oneMonthAgo))
    const [endDate, setEndDate] = useState<Date>(getEndDateTime(yesterday))
    const [comparisonStartDate, setComparisonStartDate] = useState<Date>(getStartDateTime(twoMongthsAgo))
    const [comparisonEndDate, setComparisonEndDate] = useState<Date>(getEndDateTime(oneMonthAndOneDayAgo))

    const changeStartDate = (date: Date, setDate: (date: Date) => void) => {
        setDate(getStartDateTime(date))
    }

    const changeEndDate = (date: Date, setDate: (date: Date) => void) => {
        setDate(getEndDateTime(date))
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ maxWidth: "1500px" }}>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        xs: "1fr",
                        lg: "1fr 1fr"
                    },
                    gap: "15px"
                }}>
                    <Box>
                        <DatePicker initialValue={startDate}
                                    onChange={(date) => changeStartDate(date || startDate, setStartDate)}
                                    disableFuture={true}
                                    isUTC={false}
                        />
                        <DatePicker initialValue={endDate}
                                    onChange={(date) => changeEndDate(date || endDate, setEndDate)}
                                    disableFuture={true}
                                    isUTC={false}
                        />
                    </Box>
                    <Box>
                        <DatePicker initialValue={comparisonStartDate}
                                    onChange={(date) => changeStartDate(date || comparisonStartDate, setComparisonStartDate)}
                                    disableFuture={true}
                                    isUTC={false}
                        />
                        <DatePicker initialValue={comparisonEndDate}
                                    onChange={(date) => changeEndDate(date || comparisonEndDate, setComparisonEndDate)}
                                    disableFuture={true}
                                    isUTC={false}
                        />
                    </Box>
                    <Typography>
                        {
                            `${reportsTranslation("partner.headline.mainDateRangeHeader")}
                            ${formatToLocaleTextWithDate(startDate)} - ${formatToLocaleTextWithDate(endDate)}`
                        }
                    </Typography>
                    <Typography>
                        {
                            `${reportsTranslation("partner.headline.comparisonDateRangeHeader")} 
                            ${formatToLocaleTextWithDate(comparisonStartDate)} - ${formatToLocaleTextWithDate(comparisonEndDate)}`
                        }
                    </Typography>
                    <RequestsAndLeadsReport startDate={startDate} endDate={endDate} countryCodes={countryCodes} />
                    <RequestsAndLeadsReport startDate={comparisonStartDate} endDate={comparisonEndDate} countryCodes={countryCodes} />

                    <RequestTypesReport startDate={startDate} endDate={endDate} />
                    <RequestTypesReport startDate={comparisonStartDate} endDate={comparisonEndDate} />

                    <RequestSourcesReport startDate={startDate} endDate={endDate} countryCodes={countryCodes}/>
                    <RequestSourcesReport startDate={comparisonStartDate} endDate={comparisonEndDate} countryCodes={countryCodes}/>
                </Box>
            </Box>
        </Box>
    );
};

export default OverviewReport;
