import {RelocationRequestDetail, RelocationType} from "../relocationRequest/RelocationRequestDetail";

export enum CancellationStatus {
    CANCELLED = "CANCELLED",
    REJECTED = "REJECTED",
    IN_ACCEPTANCE = "IN_ACCEPTANCE"
}

export enum RejectedReason {
    NOT_REJECTED = "NOT_REJECTED",

    PARTNER_NOT_ACTIVE = "PARTNER_NOT_ACTIVE",
    PARTNER_NOT_ACTIVE_IN_CRM = "PARTNER_NOT_ACTIVE_IN_CRM",
    PARTNER_SELF_PAUSED = "PARTNER_SELF_PAUSED",
    PARTNER_PAUSED_BY_CUSTOMERCARE = "PARTNER_PAUSED_BY_CUSTOMERCARE",
    PARTNER_DISABLED_DUE_TO_OVERDUE_PAYMENTS = "PARTNER_DISABLED_DUE_TO_OVERDUE_PAYMENTS",
    INVALID_CONTRACT = "INVALID_CONTRACT",
    INVALID_DAY_OF_THE_WEEK = "INVALID_DAY_OF_THE_WEEK",
    MIN_RELOCATION_INTERVAL = "MIN_RELOCATION_INTERVAL",
    MAX_RELOCATION_INTERVAL = "MAX_RELOCATION_INTERVAL",
    PARTNER_PAUSED = "PARTNER_PAUSED",
    REMOVED_BY_DISTRIBUTION_EXPECTATION_LEVEL = "REMOVED_BY_DISTRIBUTION_EXPECTATION_LEVEL",
    TOO_MANY_PARTNERS = "TOO_MANY_PARTNERS",
    GROUP_ALREADY_SELECTED = "GROUP_ALREADY_SELECTED"
}

export enum LeadType {
    FILTER = "FILTER",
    SPECIAL = "SPECIAL",
    UPGRADE = "UPGRADE",
    PREMIUM = "PREMIUM",
    SHOP = "SHOP",
    TOP_PARTNER = "TOP_PARTNER",
    TOP_PARTNER_GRATIS = "TOP_PARTNER_GRATIS",
}

export enum LeadGenerationResultType {
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    ALL = "ALL",
}

export type PartnerDetails = {
    cwid: string,
    scoutId: string,
    name: string,
}

export type Leads = {
    leads: Lead[];
}

export type Lead = {
    partner: PartnerDetails,
    leadType: LeadType,
    price: number,
    gratis: boolean,
    sent: Date,
    cancellationClaimDate?: Date,
    cancellationStatus?: CancellationStatus,
}

export type LeadGenerationResultsPage = {
    leads: LeadGenerationResult[],
}

export type LeadGenerationResult = {
    relocationRequestId: string,
    relocationType: RelocationType,
    createdAt: Date,
    price: number,
    fromZipCode: string,
    toZipCode?: string,
    livingSpace: number,
    rejectionReason: RejectedReason,
}

export type PartnerLeadsPage = {
    leads: PartnerLead[];
}

export type PartnerLead = {
    relocationRequest: RelocationRequestDetail,
    leadType: LeadType,
    gratis: boolean,
    sent: Date,
    price: number,
    cancellationClaimDate?: Date,
    cancellationStatus?: CancellationStatus,
}

export type PartnerLeadsStatistics = {
    totalElements: number,
    totalCancelled: number,
}
