import React, {MouseEventHandler} from "react";
import {SxProps} from "@mui/system";
import {Box, Theme} from "@mui/material";
import {useTheme} from "@mui/styles";

export const ChipItem = React.forwardRef(({children, sx, isRed, onClick}: {
    children: JSX.Element | string,
    sx?: SxProps<Theme>,
    isRed?: boolean,
    onClick?: MouseEventHandler<HTMLDivElement>
}, ref) => {
    const theme = useTheme<Theme>()

    return (
        <Box sx={{padding: '0 5px 10px 0'}}>
            <Box onClick={onClick} sx={{
                border: isRed ? `1px solid ${theme.palette.error.main}` : 'none',
                height: '30px',
                cursor: 'pointer',
                backgroundColor: theme.palette.common.white,
                borderRadius: '5px',
                fontSize: '14px',
                justifyContent: 'start',
                whiteSpace: 'nowrap',
                padding: '0 7px',
                display: 'flex',
                alignItems: 'center', ...(sx || {})
            }} ref={ref}>{children}</Box>
        </Box>
    )
});
