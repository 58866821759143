import React, {FC, ReactElement, useEffect, useState} from "react"
import MultipleRowSkeleton from "components/feedback/MultipleRowSkeleton"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
    DecimalTableCell,
    IntegerTableCell,
    StyledTableCell,
    StyledTableRow
} from "components/reportTable/ReportTableComponents";
import TableBody from "@mui/material/TableBody";
import {
    CountryRequestSourceStatistics,
    getRequestSourceStatisticsGroupedBySource, getAggregatedStatistics
} from "pages/reports/overview/RequestSourcesStatisticsService";

type RequestSourcesReportProps = {
    startDate: Date,
    endDate: Date,
    countryCodes: string[],
}

const RequestSourcesReport: FC<RequestSourcesReportProps> = ({startDate, endDate, countryCodes}): ReactElement => {
    const {t} = useTranslation('reports')
    const headerOf = (column: string) => t(`partner.header.${column}`)
    const [countrySourceStatistics, setStatistics] = useState<Record<string, CountryRequestSourceStatistics[]>>({})
    const [isLoading, setLoading] = useState<boolean>(false)
    const headers = [
        'requestSource',
        'countryCode',
        'requests',
        'revenuePerRequest',
        'leads',
        'revenuePerLead',
        'multiplier',
        'totalRevenue'].map(headerOf)

    useEffect(() => {
        if (startDate && endDate) {
            setLoading(true)
            getRequestSourceStatisticsGroupedBySource(
                countryCodes,
                startDate,
                endDate
            ).then(statistics => setStatistics(statistics))
                .finally(() => setLoading(false))
        }
    }, [startDate, endDate, countryCodes])

    if (isLoading) return <MultipleRowSkeleton rows={5} fontSize={"35px"} styles={{ height: "200px", width: "640px" }}/>

    const renderCountryStatistics = (countryStatistics: CountryRequestSourceStatistics) => (
        <>
            <StyledTableCell>{countryStatistics.countryCode}</StyledTableCell>
            <IntegerTableCell value={countryStatistics.requestCount} />
            <DecimalTableCell value={countryStatistics.revenuePerRequest} />
            <IntegerTableCell value={countryStatistics.leadCount} />
            <DecimalTableCell value={countryStatistics.revenuePerLead} />
            <DecimalTableCell value={countryStatistics.multiplier} />
            <DecimalTableCell value={countryStatistics.revenue} />
        </>
    )

    const renderTotalCountryStatistics = (source: string) => {
        const totalStatistics = getAggregatedStatistics(countrySourceStatistics[source])
        return (
            <>
                <StyledTableCell sx={{ fontWeight: "bold" }}>{ t("partner.aggregationLabel") }</StyledTableCell>
                <IntegerTableCell value={totalStatistics.totalRequests} />
                <DecimalTableCell value={totalStatistics.revenuePerRequest} />
                <IntegerTableCell value={totalStatistics.totalLeads} />
                <DecimalTableCell value={totalStatistics.revenuePerLead} />
                <DecimalTableCell value={totalStatistics.multiplier} />
                <DecimalTableCell value={totalStatistics.totalRevenue} />
            </>
        )
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map(header => <StyledTableCell sx={{ height: "12px" }}>{header}</StyledTableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    Object.keys(countrySourceStatistics).map((sourceKey: string) => (
                        <>
                            <StyledTableRow>
                                <StyledTableCell rowSpan={countrySourceStatistics[sourceKey].length + 1}>{sourceKey}</StyledTableCell>
                                { renderCountryStatistics(countrySourceStatistics[sourceKey][0]) }
                            </StyledTableRow>
                            {
                                countrySourceStatistics[sourceKey].slice(1).map((countryStatistics: CountryRequestSourceStatistics) =>
                                    <StyledTableRow key={countryStatistics.countryCode}>
                                        { renderCountryStatistics(countryStatistics) }
                                    </StyledTableRow>
                                )
                            }
                            { renderTotalCountryStatistics(sourceKey) }
                        </>
                    ))
                }
            </TableBody>
        </Table>
    )
}

export default RequestSourcesReport
